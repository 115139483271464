.modal-properties, .modal-properties {
    padding: 14px 50px;
    display: grid;
    align-content: space-between;
    justify-content: center;
}

.metamask-modal-properties {
    width: clamp(200px, 90vw, 580px);
    height: clamp(200px, 50vh, 380px);
}

.waiting-modal-properties, .confirmation-modal-properties, .success-modal-properties {
    width: clamp(200px, 90vw, 450px);
}

.waiting-modal-properties {
    height: clamp(100px, 50vh, 200px);
}

.confirmation-modal-properties {
    height: clamp(220px, 30vh, 280px);
}

.success-modal-properties {
    height: clamp(260px, 50vh, 280px);
}

.info-modal {
    padding: 14px 21px;
    width: clamp(300px, 90%, 600px);
    height: clamp(206px, 95%, 1200px);
    display: grid;
    align-content: baseline;
    overflow-y: scroll !important;
}

.info-modal::-webkit-scrollbar {
    display: none;
}

.link, .link:visited, link:active, .link:link {
    color: var(--secondary-main);
    text-decoration: none;
    display: inline-block;
}


.modal-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
    text-align: center;
}

.modal-text {
    color: gray;
    text-align: center;
    width: 95%;
}

.divider {
    background-color: var(--primary-light);
}

.button-section {
    display: flex;
    justify-content: flex-end;
    background: var(--primary-main);
}

.check-button-section {
    display: flex;
    width: 80%;
    margin: 0.5rem auto;
    justify-content: space-between;
    background: var(--primary-main);
}

.pause-modal{
    padding: 1rem !important;
}