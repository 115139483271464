input.certificate-form{
    width: 100%;
    margin-bottom: 15px;
    height: 50px;
    font-size: 17px ;
}

div.col div.certificate-form{
    width: 100%;
    margin-bottom: 15px;
    font-size: 17px ;
}

/* responsive de las propiedades en pantallas grandes */
@media (min-width: 768px) {
    .offset-md-3 {
        margin-left: 0px;
    }
}

.input-icon {
    display: flex;
    align-items: center;
    position: relative;
}

.input-icon input {
    padding-left: 35px;
}

.date-picker {
    width: 100%;
    margin-bottom: 15px;
    height: 50px;
    background-color: white;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 17xpx;
    font-weight: 400;
    line-height: 1.5;
    cursor: pointer;
    padding-left: 1rem; 
}

.certificate-button{
    padding: 15px 30px;
    font-size: 20px !important;
    border-radius: 17px;
}

#dropdown-basic{
    width: 300px;
    text-align: left;
    font-size: 17px;
    font-weight: 400;
    border-radius: 10px;
    height: 50px;
    padding-left: 3rem;
}
#dropdown-basic:hover{
    background-color:#1c4d9c;
    color: white;
    border-radius: 40px;
    transition: all 0.5s;
}

.dropdown-toggle {
    background-color: white;
    color: black;
    border: #174885 2px solid !important;
}

.dropdown-toggle::after{
    border-top: 0;
    border-right: 0;
    border-left: 0;
}

.icon{
    /* margin-right: 30px; */
    position: absolute;
    top: 9px;
    left: 255px;
    margin: 0.5rem;
    /* right: 10px; */
}

.input-container {
    position: relative;
}

.icon-template{
    position: absolute;
    top: 8px;
    left: 12px;
    margin: 0.5rem;
}

.certificate-form-alumno{
    width: 100%;
}
.certificate-form{
    margin-bottom: 15px !important;
    width: 100%;
}

.tab-component{
    margin: 0 !important;
    padding: 0 !important;
}

#dropdown-form{
    width: 200px;
}

.svg-container{
    margin-top: 2rem;
}

.svg-component-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .svg-component {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    text-align: center;
  }

h3, h4 {
    color: rgb(248, 106, 5);
    font-weight: bolder;
}

.h4-fields {
    color: black;
}

#h5-lista-participantes{
    margin-top: 5px;
}

.opcional-input{
    margin-bottom: 15px;
    height: 57px;
}

.h4-opcionales{
    font-style: italic;
}

.btn-lista{
    border: 1px solid #ff6600 !important;
    border-radius: 30px !important;
    color: #8B8B8C !important;
    padding: 5px 20px !important;
    margin-left: 80px !important;
    margin-top: -4px !important;
}

.btn-lista-disabled{
    border: 1px solid #000000 !important;
    border-radius: 30px !important;
    color: #8B8B8C !important;
    padding: 5px 20px !important;
    margin-left: 80px !important;
    margin-top: -4px !important;
}


.lista-participantes-container{
    display: flex;
}

.agregar-btn{
    padding: 0.7rem 1.5rem !important;
    border-radius: 13px !important;
}

.custom-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 375px;
    border: 1px dashed var(--main-color);
    border-radius: 5%;
    cursor: pointer;    margin-top: 1%;
    object-fit: contain !important;
}


    
 


input[type="file"] {
    display: none;
}

.subtitles {
    font-size: 18px !important;
    line-height: 21.09px !important;
}

.event-btn{
    background-color: #ff6600 !important;
    color: white !important;
    border-radius: 50px !important;
    border: 0px !important;
    width: 20%;
    padding: 0.8rem 0.5rem !important;
    margin-left: 0px;
}

.event-btn:hover{
    background-color: #C85000 !important;
}

.event-btn:disabled{
    background-color: #8b8a8a !important;
    color: white !important;
  
}

.form-nominated{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 60% !important;
    margin-top: 1rem !important;
}


