.login-background{
  background-color: #E2E2E2;
  height: 100%;
  padding-bottom: 2rem;
}

.white-background{
  margin: 0px !important;
}

.col-img{
  padding: 0px !important;
}

.container-login{
  display: flex;
  align-items: center;

  flex-direction: column;
}

.white-background{
  background-color: #FFFEFE;
  border-radius: 20px;
  margin-top: 10% !important;
  display: flex;
  justify-content: space-between;
}

.login-column{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 5rem !important;
  
}

.img-login-background{
  width: 100%;
}

.certification-badge{
  position: absolute;
  right: 20%;
  width: 10%;
  top: 3%;
  
}

.login-btn{
  border-radius: 50px !important;
  width: 75%;
  margin: 0 auto;
  padding: 0.7rem 0.6rem !important;
  border: 2px solid !important; 
}

.login-btn:hover{
  background-color: #ff6600 !important;
  color: white;
  border: 2px solid !important; 
}

.login-galicia-logo{
  width: 65%;
}

.footer-login{
  width: 100%;
  text-align: center;
  padding: 1.5rem;
  background-color: #FFFEFE;
  border-radius: 20px;
  margin-top: 5% !important;
  display: flex;
  justify-content: space-between;
}