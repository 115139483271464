.dropdown-search {
    position: relative;
    width: 300px;
  }
  
  .search-input {
    display: flex;
    align-items: center;
  }
  
  .search-input input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
  }
  
  .search-button {
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 4px 4px 0;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .search-icon {
    font-size: 16px;
  }
  
  .dropdown {
    position: absolute;
    z-index: 1;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .dropdown-option {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-option:hover {
    background-color: #f2f2f2;
  }